angular.module('a1TruckPartsApp', [
    'ngAnimate',
    'ngCookies',
    'ngResource',
    'ngRoute',
    'ngSanitize',
    'ngTouch'
]).config(['$locationProvider', function ($locationProvider) {
    $locationProvider.hashPrefix('');
}]);

(function () {
    'use strict';

    angular
        .module('a1TruckPartsApp')
        .controller('ContactCtrl', function ($scope, $http, $cookies) {
            var notification = document.querySelector('.mdl-js-snackbar'),
                data = {},
                locationData = null,
                key = 'AIzaSyDHj5gHYBbaK8cGymYrYuq9o6dEqGTCZUI',
                geo_options = {
                    enableHighAccuracy: true,
                    maximumAge: 30000,
                    timeout: 27000
                },
                latlng = null,
                search = window.location.search,
                searchParams = search?JSON.parse('{"' + search.replace(/\?/g, '').replace(/&/g, '","').replace(/=/g,'":"') + '"}',
                    function(key, value) { return key===""?value:decodeURIComponent(value) }):{};


            function geo_error() {
                console.error('Error: No geolocation available');
            }

            function locateUser(latlng) {
                $http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latlng.lat + ',' + latlng.lng + '&key=' + key)
                    .then(function (res) {
                        locationData = res.data.results[0];
                    }, function (res) {
                        geo_error();
                    });
            }

            function getGeoData() {
                if ($cookies.get('geolocation')) {
                    latlng = JSON.parse($cookies.get('geolocation'));
                    locateUser(latlng);
                } else {
                    if ("geolocation" in navigator) {
                        navigator.geolocation.getCurrentPosition(geo_success, geo_error, geo_options);

                        function geo_success(position) {
                            latlng = {lat: position.coords.latitude, lng: position.coords.longitude};
                            locateUser(latlng);
                        }
                    } else {
                        geo_error();
                    }
                }
            }

            function init() {
                getGeoData();

                $scope.contact = {};

                if(searchParams) {
                    $scope.contact.orderNumber = searchParams.orderNumber ? searchParams.orderNumber : null;
                    $scope.contact.contactEmail = searchParams.contactEmail ? searchParams.contactEmail : null;

                }
                $scope.contactData = {
                    notificationProperties: {
                        template_name: 'autowares-contact.ftl',
                        subject: 'Public website request',
                        to: ['helpdesk@autowares.com']
                    },
                    data: $scope.contact
                };
            }

            init();

            $scope.makeContact = function (isValid, email, isReturn) {
                if (isReturn) {
                    var rtnStr = 'Internet Returns';
                    $scope.contactData.data.contactName = rtnStr;
                    $scope.contactData.notificationProperties.subject = rtnStr;
                    $scope.contactData.data.message = 'Order #: ' + $scope.contact.orderNumber + '<br/>Return reason: ' + $scope.contact.reason + '<br/>Comments: ' + $scope.contact.message;
                }
                if (email !== 'undefined') {
                    $scope.contactData.notificationProperties.to = [email];
                }
                if (isValid) {
                    $scope.contactData.data.urlData = window.location.href;
                    $scope.contactData.data.locationData = locationData;
                    $scope.contactData.notificationProperties.from = $scope.contact.contactEmail;
                    // console.log($scope.contactData);
                    $http.post('/notification/v2/notify', $scope.contactData).then(function (response) {
                        if (response) {
                            if (response.status === 200) {
                                data = {
                                    message: 'Thank you, your message has been sent.',
                                    timeout: 10000
                                };
                                notification.MaterialSnackbar.showSnackbar(data);
                                init();
                                $scope.contactForm.$setPristine();
                            }
                        }
                    }, function (response) {
                        data = {
                            message: 'We\'re sorry, there was a problem sending your message, please try again.',
                            timeout: 10000
                        };
                        notification.MaterialSnackbar.showSnackbar(data);
                    });
                }
            };
        });
}());
